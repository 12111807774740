<template>
  <div class="examples">
    <UserTable heading="Users" />
  </div>
</template>

<script>
import UserTable from '@/components/UserTable.vue';

export default {
  name: 'default',
  components: {
    UserTable,
  },
  props: {},
  data() {
    return {
      exRadioList: null,
      exChecked: false,
      exSwitch: false,
      selectedRows: [],
      selectedOption: null,
      options: [
        {
          label: 'Text only',
          type: 'text',
        },
        {
          label: 'Image',
          type: 'image',
        },
        {
          label: 'Video',
          type: 'video',
        },
        {
          label: 'Link',
          type: 'link',
        },
        {
          label: 'Personality Quiz',
          type: 'quiz',
        },
        {
          label: 'Trivia',
          type: 'trivia',
        },
      ],
    };
  },
  created() {},
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.examples {
  .example {
    padding: 10px;
    margin-bottom: 10px;
    border: 2px solid white;
    border-radius: 10px;

    ::v-deep {
      .header {
        padding: 10px;
      }
      .collapse-wrapper > .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}
.buttons {
  display: flex;
  justify-content: flex-end;
}
::v-deep .passport-button.default.danger {
  margin-right: 10px;
  font-size: 14px;
  text-transform: uppercase;
}
::v-deep .passport-button.default.primary,
.passport-button.default.danger {
  font-size: 14px;
  text-transform: uppercase;
}
::v-deep .g-input .input-element:not([type='submit'], [type='range']) {
  width: 315px;
  font-size: 18px;
}
</style>
